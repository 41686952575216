import React from "react"

export const Header = ({data, style}) => {
  const {title, description} = data;
  return (
    <header style={style.header.navbar}>
        <div className="collapse" id="navbarHeader">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-md-7 py-4">
                        <h4>Informations</h4>
                        <p>{ description.map(d => <div className="text-muted">{d}</div>) }</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="navbar shadow-sm navbar-light"  style={style.header.navbar}>
            <div className="container">
            <a href="/" className="navbar-brand d-flex align-items-center">
                <strong style={style.header.title}>{title}</strong>
            </a>
            <button className="navbar-toggler collapsed" style={style.header.toggler} type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            </div>
        </div>
    </header>
  )
}