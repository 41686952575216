export const DATA = {
  title: "SBFB - Training",
  description: [
    "Savate Boxe Française Bordeaux",
    "Stade Chaban Delmas, Place Johnston, 33000 Bordeaux"
  ],
  style: {
    header: {
      title: {
        color: "#2f3a8b",
      },
      navbar: {
        color: "#2f3a8b",
        backgroundColor: "#F0",
        boxShadow: "0 0.25rem 0.75rem rgba(0, 0, 0, .05)"
      },
      toggler: {
        borderColor: "transparent",
      }
    },
    details: {
      color: "white",
      backgroundColor: "#F0",
    },
    training: {
      color: "#2f3a8b",
      backgroundColor: "white",
      margin: "auto"
    },
    exercices: {
      fontSize: "18px",
      padding: "20px",
      color: "black",
    },
    subproduct: {
      color: "white",
      backgroundColor: "#404040",
    },
  },
  details: {
    title: "Carte",
  },
  trainings: [
    {
      title: "26/07/2023",
      exercices: [
        "L'entraînement de la semaine sera un petit défi :", 
        "- Réaliser le plus vite possible 100 burpees.",
        "Je rappelle que la poitrine touche le sol sur les burpees sinon ça compte pas, jouez le jeu à fond. ", 
        "J'attends avec impatience vos chrono 💪🏽💪🏽💪🏽"
      ]      
    },
    {
      title: "16/07/2023",
      exercices: [
        "Séance Tabata 20sec de travail sur chaque  exos 10 sec recup pendant 4 min, récupérez 2 min entre chaque tabata.", 
        "1er Tabata pour s'échauffer : <br/>- Jumping jack <br/>- Up and down.",
        "2eme tabata :<br/>- Pompe<br/>- Direct dans le vide en levant les genoux", 
        "3eme tabata :<br/>- Squat<br/>- Chassé contre un mur ou autre si impossible chassé dans le vide", 
        "4eme tabata (Mon préféré) :<br/>- Burpees ( visez un mini de 6 rep )",
        "N'hésitez pas à m'envoyez  vos ressentis 💪🏽🥊",
        "Bonne chance la team 😉"
      ]
      
    },
    {
      title: "09/07/2023",
      exercices: [
        "Courir à allure tranquille (allure à laquelle on peut parler). 30 min pour les habitués, 20min pour les débutants.",
        "Privilégiez les parc ou les sols sont moins rigides.",
        "De préférence, dans un parc un peu ombragé pour éviter les grosses chaleurs.",
        "Essayez de trouver des partenaires de course, c'est plus motivant à faire en groupe ;)"
      ]
    },
  ]
      
};
