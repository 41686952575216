import React from "react";
import "./App.css";
import { Header } from "./components/Header";
import { DATA } from "./data/data";
import { Details } from "./components/Details";
import { Training } from "./components/Training";

const App = () => {
  document.getElementById("html").style = `background-color: ${DATA.style.details.backgroundColor}`;

  return (
    <div className="App" style={{backgroundColor: DATA.style.details.backgroundColor}}>
      <Header data={DATA} style={DATA.style} />
      <Details details={DATA.details} style={DATA.style} />
      <div id="accordion" className="col-lg-8 col-md-12 mx-auto" >
        { DATA.trainings.map((c, index) => <Training key={index} i={index} data={c} style={DATA.style}  /> )}
      </div>
    </div>
  )
}

export default App
