import React from "react";

export const Training = ({i, data, style}) => {
    const { title, exercices } = data;
    const target = `collapse${i}`;
    const collapse = i === 0 ? "collapse show" : "collapse";
    const heading = `heading${i}`;
    return (
        <div style={style.training} className="card">
            <div className="card-header" id={heading}>
                <h2>
                    <button className="btn btn-link category" data-toggle="collapse" data-target={`#${target}`} aria-expanded="true" aria-controls={target}>
                        {title}
                    </button>
                </h2>
            </div>
            <div id={target} className={collapse} aria-labelledby={heading} data-parent="#accordion" style={style.exercices}>
            { exercices.map((p, index) => <p  key={index} dangerouslySetInnerHTML={{__html: p}}/>)}
            </div>
        </div>
    )
}