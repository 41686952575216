import React from "react";
import logo from '../assets/logo_dark.svg';

export const Details = ({ details }) => {
    return (
        <div className="col-lg-6 col-md-8 mx-auto my-5 details" >
        <img className="logo" alt="logo" src={logo} />
        {/* <h1 className="fw-light" style={style.details}>{title}</h1>
        <p className="lead text-muted">{description}</p> */}
      </div>
    )
}